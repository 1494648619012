<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />

			<!-- <div class="message">
				<div><img :src="item.surfacePlot" alt="" class="img"></div>
				<div class="messageInfo">
					<div class="title">{{item.name}}</div>
					<div class="messageItem">主讲老师：{{item.teacherName}}</div>
					<div v-if="status === 2" class="messageItem">预约人数：{{item.bookedPeople}}</div>
					<div class="messageItem">直播时间：{{item.beginTime}} 到 {{item.endTime}}</div>
					<div class="messageItem">所属类别：{{item.firstCourseClassifyName}}</div>
					<div class="messageItem" v-if="status === 1">学习费用：{{item.cost}}</div>
					<div class="briefInfo">培训介绍</div>
					<div class="introduce">
						{{item.introduce}}
					</div>
					<div v-if="status === 2">
						<el-button class="bnt" @click="handleReservationNow" icon="el-icon-alarm-clock">立即预约</el-button>

					</div>
					<div v-else>
						<el-button class="bnt" icon="el-icon-refresh">直播回看</el-button>
					</div>
				</div>
			</div> -->
			<div class="details" v-if="details">
				<div class="details_l">
					<img :src="details.surfacePlot" alt="">
				</div>
				<div class="details_r">
					<div class="name">{{details.name}}</div>
					<div class="teacher">主讲教师：{{details.teacherName}}</div>
					<div class="people_num" v-if="details.status == 1">在线人数：{{details.viewPeople}}人</div>
					<div class="people_num" v-else>预约人数：{{details.bookedPeople}}人</div>
					<div class="time">直播时间：{{details.beginTime}} 到 {{details.endTime}}</div>
					<div class="class">所属类别：{{details.firstCourseClassifyName}}</div>
					<div class="cost">学习费用：<span style="color:#E51E0B;">{{details.cost}}</span></div>
					<h4 class="briefInfo">培训介绍</h4>
					<div class="introduce">{{details.introduce}}</div>
					<div class="btn_group">
						<div class="btn reserve_btn" v-if="!details.stuToLive" @click="handleReservationNow">
							<img src="../../assets/live/icon_no_booked.png" alt="">
							<span>立即预约</span>
						</div>
						<div class="btn" v-if="details.stuToLive && details.status == 0">
							<img src="../../assets/live/icon_booked.png" alt="">
							<span>未开始</span>
						</div>
						<div class="btn reserve_btn" v-if="details.stuToLive && details.status == 1"
							@click="handleWatchLive">
							<img src="../../assets/live/liveNow.png" alt="">
							<span>正在直播</span>
						</div>
						<div class="btn" v-if="details.stuToLive && details.status == 2">
							<img src="../../assets/live/icon_finished.png" alt="">
							<span>已结束</span>
						</div>
					</div>
					<!-- <el-button class="btn" v-if="details.stuToLive && details.status == 0" icon="el-icon-alarm-clock">未开始</el-button>
					<el-button class="btn" v-if="details.stuToLive && details.status == 1" icon="el-icon-alarm-clock">正在直播</el-button>
					<el-button class="btn" v-if="details.stuToLive && details.status == 2" icon="el-icon-alarm-clock">已结束</el-button>
					<el-button class="btn" v-if="!details.stuToLive" @click="handleReservationNow" icon="el-icon-alarm-clock">立即预约</el-button>
					<el-button class="btn" icon="el-icon-refresh" v-if="false">直播回看</el-button> -->
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播课堂"],
				status: 1,
				details: {}
			}
		},
		created() {
			this.getLiveInfo()
			// //回看详情
			// if (this.$route.query.status === '1') {
			// 	this.status = 1
			// 	this.item = this.$route.query.it
			// } else {
			// 	this.status = 2
			// 	this.item = this.$route.query.it
			// 	this.id = localStorage.getItem('cousreId')
			// 	this.getLiveInfo(this.id)
			// }
		},
		computed: {
			...mapState(['isLogin'])
		},
		methods: {
			// 获取直播的详细信息
			getLiveInfo() {
				this.$http.post('liveById', {
					id: this.$route.query.id
				}).then(res => {
					if (res.code === 200) {
						this.details = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 立即预约
			handleReservationNow() {
				// 保存id，防止刷新
				localStorage.setItem('cousreId', this.details.id)
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/trainingProgram/paymentOrder',
					query: {
						index: 4,
						type: 1,
						data: this.details
					}
				})
			},
			// 观看直播
			handleWatchLive() {
				this.$router.push({
					path: '/liveInfo',
					query: {
						id: this.details.id
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: 1200px;
		margin: auto;
	}

	.details {
		display: flex;
		margin-top: 50px;
		margin-bottom: 120px;

		.details_l {
			width: 451px;
			height: 313px;

			img {
				width: 451px;
				height: 313px;
			}
		}

		.details_r {
			flex: 2;
			margin-left: 53px;
			padding: 34px 54px 23px 39px;
			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);

			.name {
				font-size: 24px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 30px;
			}

			.teacher,
			.people_num,
			.time,
			.class,
			.cost {
				font-size: 18px;
				color: #666666;
				margin-bottom: 28px;
			}

			.briefInfo {
				font-size: 18px;
				font-weight: bold;
				color: #E51E0B;
				margin-bottom: 22px;
				margin-top: 34px;
			}

			.introduce {
				font-size: 16px;
				color: #333333;
				margin-bottom: 35px;
				text-indent: 2em;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
			}

			.btn_group {
				.btn {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 151px;
					height: 53px;
					line-height: 53px;
					background: #EEEEEE;
					border-radius: 5px;
					font-size: 18px;
					font-weight: 500;
					color: #999999;
					cursor: pointer;

					span {
						margin-left: 15px;
					}

					img {
						width: 24px;
					}
				}

				.living_btn {
					background: #FBAF25;
					color: #ffffff;
				}

				.reserve_btn {
					background: #E51E0B;
					color: #ffffff;
				}
			}
		}
	}

	// .content {
	// 	background: #fff;

	// 	.messageInfo {
	// 		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
	// 		padding: 40px;
	// 		width: 1000px;
	// 	}
	// }

	// .info {
	// 	width: 1455px;
	// 	margin: auto;

	// 	.message {
	// 		margin-top: 60px;
	// 		display: flex;
	// 		margin-bottom: 140px;

	// 		.img {
	// 			width: 451px;
	// 			height: 313px;
	// 			margin-right: 53px;
	// 		}

	// 		.title {
	// 			font-weight: 500;
	// 			color: #333333;
	// 			font-size: 24px;
	// 			margin-bottom: 30px;
	// 		}

	// 		.messageItem {
	// 			margin-bottom: 28px;
	// 			color: #666666;
	// 			font-weight: 400;
	// 			font-size: 18px;
	// 		}

	// 		.briefInfo {
	// 			font-weight: bold;
	// 			color: #E51E0B;
	// 			margin-bottom: 22px;
	// 		}

	// 		.introduce {
	// 			font-size: 16px;
	// 			font-weight: 400;
	// 			color: #333333;
	// 			margin-bottom: 35px;
	// 		}

	// 		.bnt {
	// 			color: #FFFFFF;
	// 			background: #E51E0B;
	// 		}
	// 	}
	// }
</style>
